import React from "react";

// 未进行
function Icon({className, fill="#BBCFDD"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={className}
      viewBox="0 0 1024 1024"
    >
      <path
        fill={fill}
        d="M0 512a512 512 0 101024 0A512 512 0 100 512z"
      ></path>
      <path
        fill="#FFF"
        d="M294.798 294.798a307.2 307.2 0 11434.404 434.404 307.2 307.2 0 01-434.404-434.404zm400.27 96.142l-304.07 304.128a219.477 219.477 0 00304.07-304.07zm-338.204-34.133a219.477 219.477 0 00-27.932 276.196l304.07-304.071a219.477 219.477 0 00-276.138 27.875z"
      ></path>
    </svg>
  );
}

export default Icon;