import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  // 职位id
  jobId: undefined,
  // 搜索日期
  dateStringArr: [null, null],
};

export const reportIndex = createSlice({
  name: "reportIndex",
  initialState,
  reducers: {
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setDateStringArr: (state, action) => {
      state.dateStringArr = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJobId, setDateStringArr } = reportIndex.actions;

export default reportIndex.reducer;
