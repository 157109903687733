import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { emptySplitApi } from "src/services";

import counterReducer from "./features/counterSlice";
import teamUser from "./features/teamUser";
import tableCandidate from "./features/tableCandidate";
import reportIndex from './features/reportIndex'
import reportDetail from './features/reportDetail'
import uploadResume from "./features/uploadResume";
import workbench from "./features/workbench";
import interviewTable from "./features/interviewTable";
import talentTable from "./features/talentTable";
import jobTable from "./features/jobTable";
import myTable from "./features/myTable";


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    teamUser,
    tableCandidate,
    reportIndex,
    reportDetail,
    uploadResume,
    workbench,
    interviewTable,
    talentTable,
    jobTable,
    myTable,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([
      emptySplitApi.middleware,
      // httpErrorMiddleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
