import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { setUserInfo } from "src/store/features/teamUser";
import {
  getTeamKeyStorage,
  getLoginToken,
  setToken,
  setTeamKeyStorage,
  getCorpId,
} from "src/utils/token";
import { connect, useDispatch } from "react-redux";
import { authApi } from "src/services/auth";

import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingComp from "src/components/LoadingComp";
import { isMobile } from "src/utils/platform";
import { setIsLogin } from "src/store/features/teamUser";
import { logger } from "src/utils/logger";
import { isDingEnv, requestAuthCode } from "src/utils/dd";
import { usersApi } from "src/services/users";
import { notification } from "antd";

const Auth = ({ getUserInfo, userInfo, errorCode, isLogin, ddLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const corpId = searchParams.get("corpId");

  useEffect(() => {
    // 钉钉登录
    function funcDdLogin() {
      logger("corpId", isDingEnv(), corpId);
      if (!isDingEnv()) {
        navigate("/login", { replace: true });
        return;
      }
      if (!corpId) {
        notification.error({
          message: "登录报错",
          description: "corpId 不存在，请重新从工作台打开",
        });
        return;
      }

      requestAuthCode({ corpId })
        .then((info) => {
          logger("requestAuthCode", info);
          const { code } = info;
          if (code) {
            const platform = isMobile() ? "WEB_MOBILE" : "PC";
            ddLogin({ code, corpId, platform }).then((res) => {
              const { data } = res;
              if (data?.code === 200) {
                const { token, tokenPrefix, teamKey, expireTime } = data.data;
                setToken({
                  token: `${tokenPrefix} ${token}`,
                  expireTime,
                  corpId,
                });
                setTeamKeyStorage(teamKey);
                dispatch(setIsLogin(true));
                // 获取用户信息
                funcGetUserInfo();
              } else if (data?.code === 18404) {
                // 钉钉授权SUITE未推送
                setTimeout(() => {
                  funcDdLogin();
                }, 1000);
              } else {
                dispatch(setIsLogin(false));

                notification.error({
                  message: "登录报错",
                  description: res?.data?.message,
                });
              }
            });
          } else {
            notification.error({
              message: "登录报错",
              description: "requestAuthCode 错误",
            });
          }
        })
        .catch((e) => {
          // console.error("requestAuthCode failed", e);
          throw new Error(`requestAuthCode failed：${JSON.stringify(e)}`);
        });
    }

    // 获取用户信息
    function funcGetUserInfo() {
      const teamKey = getTeamKeyStorage();
      const token = getLoginToken();

      const checkCorpId = () => {
        if (!corpId) {
          return true;
        }
        return getCorpId() === corpId;
      };

      if (teamKey && token && checkCorpId()) {
        getUserInfo()
          .then((res) => {
            if (res?.data.code === 200) {
              dispatch(setUserInfo(res.data.data));
              dispatch(setIsLogin(true));
            } else {
              funcDdLogin();
            }
          })
          .catch((e) => {
            funcDdLogin();
          });
      } else {
        funcDdLogin();
      }
    }

    funcGetUserInfo();
  }, [corpId, ddLogin, dispatch, getUserInfo, navigate]);

  useEffect(() => {
    if (isLogin === false) {
      // 未登录
      navigate(`/401?corpId=${corpId || ""}`, { replace: true });
    }
  }, [isLogin, navigate, corpId]);

  useEffect(() => {
    if (errorCode === 502) {
      // 升级中
      navigate("/502", { replace: true });
    }
    if (errorCode === 403) {
      // 无权限
      navigate("/403", { replace: true });
    }
  }, [errorCode, navigate]);

  return <div>{userInfo.key ? <Outlet /> : <LoadingComp />}</div>;
};

const mapState = (state) => {
  return {
    userInfo: state.teamUser.userInfo,
    isLogin: state.teamUser.isLogin,
    errorCode: state.teamUser.errorCode,
  };
};

const mapDispatch = {
  getUserInfo: usersApi.endpoints.getUserInfo.initiate,
  ddLogin: authApi.endpoints.ddLogin.initiate,
};

export default connect(mapState, mapDispatch)(Auth);
