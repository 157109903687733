import React from "react";

import { TrackerSend } from "src/utils/tracker";

// 组件错误捕获
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error.toString());
    console.log(errorInfo);
    TrackerSend({
      kind: "stability",
      type: "blank",
      errorType: "componentError",
      message: error.toString(),
      info: errorInfo.componentStack,
    });
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  reset() {
    this.setState({ error: null, errorInfo: null });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>
            发生了错误，请{" "}
            <span
              onClick={this.reset.bind(this)}
              className="primary-color pointer"
            >
              再试一次
            </span>{" "}
            或者联系客服
          </h2>
          <div>{this.state.error && this.state.error.toString()}</div>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
