import React from "react";
import { Spin } from "antd";

import styles from "./index.module.less";

const LoadingComp = ({tip}) => {
  return (
    <div className={styles.loading}>
      <Spin size="large" tip={tip} />
    </div>
  );
};

export default LoadingComp;
