import * as dd from "dingtalk-jsapi";
import { sendMessageToSingleChat } from "dingtalk-jsapi/plugin/coolAppSdk";
import { message } from "antd";
import { APPID, goodsToken, goodsCode } from "src/constants";
import { getCorpId } from "src/utils/token";
import { logger } from "./logger";
import { TrackerSend } from "./tracker";

export function isDingEnv() {
  if (dd.env.platform !== "notInDingTalk") {
    return true;
  }
  return false;
}

export const requestAuthCode = dd.runtime.permission.requestAuthCode;

// 打开侧边栏
export const openSlidePanel = dd.biz.util.openSlidePanel;

// 预览图片
export const previewImage = dd.biz.util.previewImage;

export const cspacePreview = dd.biz.cspace.preview;

// PC端打开新弹窗页面
export const invokeWorkbench = dd.biz.util.invokeWorkbench;

// 打开目标页面 https://open.dingtalk.com/document/isvapp-client/open-the-target-page
export const openLink = dd.biz.util.openLink;

// 选择人 https://open.dingtalk.com/document/orgapp-client/select-department-and-person
export const complexPicker = dd.biz.contact.complexPicker;

// 选择部门 https://open.dingtalk.com/document/orgapp-client/select-department-information-h5
export const departmentsPicker = dd.biz.contact.departmentsPicker;

// 拨打电话 https://open.dingtalk.com/document/orgapp-client/make-a-single-call-option-customizable-h5
export const quickCallList = dd.biz.telephone.quickCallList;

// 检查某企业的办公电话开通状态 https://open.dingtalk.com/document/orgapp-client/check-the-status-of-office-telephones-of-an-enterprise-h5
export const checkBizCall = dd.biz.telephone.checkBizCall;

// JSAPI鉴权
export function initConfig(dingAuth) {
  dd.error((err) => {
    TrackerSend({
      errorType: "ddError",
      message: "initConfig error",
      info: JSON.stringify(err),
    });
    message.error(`${err?.errorMessage || "签名检验失败"} ${err?.errorCode}`);
  });
  return dd.config({
    ...dingAuth,
    jsApiList: [
      "biz.contact.complexPicker",
      "biz.contact.departmentsPicker",
      "biz.contact.choose",
      "biz.telephone.quickCallList",
      "biz.telephone.checkBizCall",
      "biz.ding.create",
      "biz.cspace.preview",
      "biz.chat.pickConversation",
      "biz.util.invokeWorkbench",
    ],
  });
}

// 打开联系客服侧边栏-只能在正式环境使用
export function openContact() {
  const url = `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?wh_biz=tm&showmenu=false&goodsCode=${goodsCode}&corpId=${getCorpId()}&token=${goodsToken}`;
  logger("openContact", url);
  openSlidePanel({ url, title: "联系客服" })
    .then((res) => {
      logger(res);
    })
    .catch((e) => {
      logger(e);
    });
}

/**
 * 生成钉钉工作台打开链接
 * @param string url
 * @returns
 */
export function createWorkbenchUrl(url) {
  if (isDingEnv()) {
    return `dingtalk://dingtalkclient/action/openapp?ddtab=true&corpid=${getCorpId()}&container_type=work_platform&app_id=${APPID}&redirect_type=jump&redirect_url=${encodeURIComponent(
      url
    )}`;
  }

  return url;
}

// 创建钉消息
export const createUrlDing = ({ userId, content, url, title }) => {
  logger("createUrlDing", url);
  return dd.biz.ding.create({
    users: Array.isArray(userId) ? userId : [userId],
    corpId: getCorpId(),
    text: content,
    type: 2,
    alertType: 2,
    attachment: {
      // title: string;
      //   url: string;
      //   image: string;
      //   text: string;
      title,
      url,
      image:
        "https://online-sczp.oss-cn-zhangjiakou.aliyuncs.com/static/icon/shicai_logo.jpg",
      // text:content,
    },
  });
};

/**
 * 生成钉钉侧边栏打开链接
 * @param string url
 * @returns
 */
export function createSlideUrl(url) {
  if (isDingEnv()) {
    const text = encodeURIComponent(url);
    return `dingtalk://dingtalkclient/page/link?url=${text}&pc_slide=true`;
  }

  return url;
}

// 用户身份发送卡片消息到单聊
// https://open.dingtalk.com/document/orgapp-client/send-messages-to-one-on-one-chat
export function ddSendMessageToSingleChat(params) {
  if (!isDingEnv()) {
    message.error("请在钉钉环境发送卡片消息");
    return;
  }
  const _params = {
    context: {
      clientId: params.clientId,
      corpId: params.corpId,
    },
    userIdList: params.userIdList,
    sendCardRequest: {
      cardData: {
        cardParamMap: params.cardData,
      },
      cardTemplateId: params.cardTemplateId,
      outTrackId: params.outTrackId,
      pullStrategy: params.pullStrategy,
      callbackRouteKey: params.callbackRouteKey,
      privateData: params.privateData,
    },
  };

  logger("sendMessageToSingleChat", _params);
  return sendMessageToSingleChat(_params);
}
