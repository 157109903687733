import AcceptedIcon from "src/svgs/AcceptedIcon";
import RejectedIcon from "src/svgs/RejectedIcon";
import UnfinishedIcon from "src/svgs/UnfinishedIcon";
import TodoIcon from "src/svgs/TodoIcon";
import PendingIcon from "src/svgs/PendingIcon";

// 面试相关
import PhoneIcon from "src/svgs/PhoneIcon";
import VideoIcon from "src/svgs/VideoIcon";
import OnsiteIcon from "src/svgs/OnsiteIcon";

// 钉钉应用配置
export const APPID =
  process.env.REACT_APP_ENV === "production" ? "127606" : "127666";

export const MiniAppId =
  process.env.REACT_APP_ENV === "production"
    ? "5000000004612257"
    : "5000000004616776";

export const SuiteId =
  process.env.REACT_APP_ENV === "production" ? "28755024" : "28876021";

export const SuiteKey =
  process.env.REACT_APP_ENV === "production"
    ? "suitespbw1xupxrvb7jca"
    : "suitep8xlc3a6ull08ni4";

export const goodsCode = "DT_GOODS_881617256909241";
export const goodsToken = "5b2fe05c16e2aef1d7e204ad8ed16373";

// 面试结果状态
export const INTERVIEW_RESULT_ENUM = {
  ACCEPTED: {
    Icon: AcceptedIcon,
    name: "通过",
    key: "ACCEPTED",
  },
  REJECTED: {
    Icon: RejectedIcon,
    name: "不通过",
    key: "REJECTED",
  },
  UNFINISHED: {
    Icon: UnfinishedIcon,
    name: "未进行",
    key: "UNFINISHED",
  },
  TODO: {
    Icon: TodoIcon,
    name: "待定",
    key: "TODO",
  },
  PENDING: {
    Icon: PendingIcon,
    name: "未反馈",
    key: "PENDING",
  },
  CLOSED: {
    Icon: null,
    name: "已关闭",
    key: "CLOSED",
  },
};

// 面试官筛选
export const INTERVIEWER_FILTER = {
  NOT_PUSH: "未推送",
  NOT_ARRANGED: "待筛选",
  ACCEPTED: "通过",
  REJECTED: "不通过",
  PENDING: "未反馈",
  UNFINISHED: "未进行",
};

export const CANDIDATE_DEGREES_LABELS = {
  高中: "高中",
  专科: "专科",
  中专: "中专",
  大专: "大专",
  本科: "本科",
  硕士研究生: "硕士研究生",
  博士: "博士",
};

export const STATUS_ENUM = {
  // 初筛阶段
  WAITING: "WAITING",
  // 面试阶段
  INTERVIEWING: "INTERVIEWING",
  // 简历评估
  FILTER: "FILTER",
  // 沟通录用
  ENROLLING: "ENROLLING",
  // 待入职
  ENROLLED: "ENROLLED",
  // 已入职
  PASSED: "PASSED",
  // 已淘汰
  CLOSED: "CLOSED",
  // 已归档
  ARCHIVE: "ARCHIVE",
};

export const CANDIDATE_TYPES_LABELS = {
  WAITING: "初筛阶段",
  FILTER: "简历评估",
  INTERVIEWING: "面试阶段",
  ENROLLING: "沟通录用",
  ENROLLED: "待入职",
  PASSED: "已入职",
  CLOSED: "已淘汰",
  ARCHIVE: "已归档",
};

// 字段数据
export const DICT_TYPES = {
  // 面试地址
  ONSITE_LOCATION: "ONSITE_LOCATION",
  REASON_FOR_ELIMINATION: "REASON_FOR_ELIMINATION",
  // 来源
  RESUME_SOURCE: "RESUME_SOURCE",
  // 渠道
  RESUME_CHANNEL: "RESUME_CHANNEL",
  // 面试轮次
  INTERVIEW_ROUND: "INTERVIEW_ROUND",
  // 职位
  JOB: "JOB",
};

// 候选人录用状态
export const HIRE_STATUS = {
  UN_PASS: "未录用",
  PASS: "已录用",
};

// 录用offer审批
export const HIRE_OFFER_STATUS = {
  PROCESS: "审批中",
  SUCCESS: "审批通过",
  FAILED: "未通过",
};

// 录用offer发送
export const HIRE_NOTICE_STATUS = {
  UNFINISHED: "未发送",
  PENDING: "已发送",
};

// 候选人是否接受
export const ACCEPT_HIRE_INVITE_STATUS = {
  UNFINISHED: "未反馈",
  ACCEPTED: "接受",
  REJECTED: "拒绝",
};

// 面试类型
export const INTERVIEW_TYPE = {
  TELEPHONE: {
    key: "TELEPHONE",
    name: "电话面试",
    Icon: PhoneIcon,
    template: "PHONE_INTERVIEW_NOTICE",
    emailSettingTitle: "电话面试通知邮件",
    color: "geekblue",
  },
  VIDEO: {
    key: "VIDEO",
    name: "视频面试",
    Icon: VideoIcon,
    template: "VIDEO_INTERVIEW_NOTICE",
    emailSettingTitle: "视频面试通知邮件",
    color: "blue",
  },
  ONSITE: {
    key: "ONSITE",
    name: "现场面试",
    Icon: VideoIcon,
    template: "ON_SITE_INTERVIEW_NOTICE",
    emailSettingTitle: "现场面试通知邮件",
    color: "green",
  },
  EXAM: {
    key: "EXAM",
    name: "笔试",
    Icon: OnsiteIcon,
    template: "EXAM_INTERVIEW_NOTICE",
    emailSettingTitle: "笔试通知邮件",
    color: "cyan",
  },
};

// 职位权限配置字段
export const JOB_INFO = {
  // 职位可见性
  visualScope: "visualScope",
  // 职位下简历可操作性
  candidateVisualScope: "candidateVisualScope",
};

// 职位可见性
export const VISUAL_SCOPE = {
  HR_COLLABORATOR: {
    key: "HR_COLLABORATOR",
    name: "默认（HR成员、招聘协作人可见）",
  },
  PRINCIPAL: {
    key: "PRINCIPAL",
    name: "仅此招聘负责人和协作人可见",
  },
};

// 职位下简历可操作性
export const CANDIDATE_VISUAL_SCOPE = {
  HR_COLLABORATOR: {
    key: "HR_COLLABORATOR",
    name: " 默认（HR成员、招聘协作人、简历负责人可操作）",
  },
  PRINCIPAL: {
    key: "PRINCIPAL",
    name: "仅此招聘负责人、协作者和简历负责人可操作",
  },
};

// 允许“招聘协作人”的操作
export const COLLABORATOR_PERMISSIONS = {
  UPLOAD_CANDIDATE: {
    name: "上传简历到职位",
    key: "UPLOAD_CANDIDATE",
    disabled: false,
  },
  ASSIGN_SCREEN: {
    name: "安排简历评估",
    key: "ASSIGN_SCREEN",
    disabled: true,
  },
  ASSIGN_REVIEW: {
    name: "安排面试",
    key: "ASSIGN_REVIEW",
    disabled: true,
  },
  CREATE_OFFER: {
    name: "创建/查看offer信息",
    key: "CREATE_OFFER",
    disabled: false,
  },
};

// 上传状态
export const UPLOAD_STATUS = {
  // 进来就上传中
  upload: "upload",
  // 解析失败
  warning: "warning",
  // 上传失败
  error: "error",
  // 上传成功
  success: "success",
  // 保存成功
  saved: "saved",
  // 解析中
  parsing: "parsing",
};

/**
 * 自定义字段类型
 */
export const CustomFieldsType = {
  /**
   * 招聘需求字段
   */
  RECRUITMENT_NEEDS: "RECRUITMENT_NEEDS",
  /**
   * 职位字段
   */
  JOB: "JOB",
  /**
   * 候选人
   */
  CANDIDATE: "CANDIDATE",
};

/**
 * 自定义输入类型
 */
export const CustomComponentType = {
  text: {
    name: "文本(单行)",
    // Antd Col 占的格数
    span: 12,
  },
  textarea: {
    name: "文本(多行)",
    span: 24,
  },
  radio: {
    name: "单选",
    span: 24,
  },
  checkbox: {
    name: "多选",
    span: 24,
  },
  date: {
    name: "日期",
    span: 12,
  },
  number: {
    name: "数字",
    span: 12,
  },
  file: {
    name: "附件",
    span: 24,
  },
};

/**
 * 自定义字段权限可见性
 */
export const CustomFieldsVisible = {
  ALL: {
    name: "全部可见",
  },
  ADMIN_OR_HR: {
    name: "仅HR可见",
  },
};

// 候选人页面的职位菜单
export const JOB_MENU_ENUM = {
  all: {
    key: "all",
    name: "全部职位",
    jobRole: undefined,
  },
  principal: {
    key: "principal",
    name: "负责招聘的职位",
    jobRole: "ADMIN",
  },
  collaborator: {
    key: "collaborator",
    name: "协作招聘的职位",
    jobRole: "COLLABORATOR",
  },
  manager: {
    key: "manager",
    name: "可查看的职位",
    jobRole: "MANAGER",
  },
};

export const FILE_TYPES = {
  // 简历源文件
  RESUME: "RESUME",
  // 简历预览
  RESUME_PREVIEW: "RESUME_PREVIEW",
  // 附件
  ATTACHMENT: "ATTACHMENT",
  // 邮件源文件
  EMAIL: "EMAIL",
  // offer审批附件 其他
  OFFER_ATTACHMENT: "OFFER_ATTACHMENT",
  // offer审批附件 背调
  OFFER_BACKGROUND_SURVEY_ATTACHMENT: "OFFER_BACKGROUND_SURVEY_ATTACHMENT",
  // offer审批附件 录用通知
  OFFER_NOTICE_ATTACHMENT: "OFFER_NOTICE_ATTACHMENT",
  // offer发送给候选人的通知附件
  OFFER_SEND_ATTACHMENT: "OFFER_SEND_ATTACHMENT",
  // offer邮件模板设置中的默认附件
  OFFER_DEFAULT_SEND_ATTACHMENT: "OFFER_DEFAULT_SEND_ATTACHMENT",
};

// 职位状态枚举
export const JOB_STATUS_ENUM = {
  OPEN: {
    name: "进行中",
    color: "rgba(67, 207, 124, 1)",
  },
  CLOSED: {
    name: "关闭",
    color: "rgba(255, 141, 26, 1)",
  },
  APPROVAL_PROCESS: {
    name: "审批中",
    color: "#D1D1D1",
  },
  APPROVAL_REJECT: {
    name: "审批失败",
    color: "#F74545",
  },
};
