import { createSlice } from "@reduxjs/toolkit";

function init() {
  return {
    // 选择的参数
    params: {
      key1: "filter",
      // key2: "PENDING",
      // key3: "",
    },
    // 查询的参数
    fetchParams: {
      // page: 1,
      // size: 30,
      // statusList: "PENDING",
      // waitEvaluate: true,
      // hideCloseJob: 1,
    },
  };
}

export const myTable = createSlice({
  name: "myTable",
  initialState: init(),
  reducers: {
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
    setFetchParams: (state, action) => {
      state.fetchParams = {
        ...state.fetchParams,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setParams, setFetchParams } = myTable.actions;

export default myTable.reducer;
