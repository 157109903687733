import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // 用户信息
  userInfo: {},

  // 是否登录, null false true
  isLogin: null,
  // 接口特殊错误码，正常是200
  errorCode: 200,
};

export const teamUser = createSlice({
  name: "teamUser",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, setIsLogin, setErrorCode } = teamUser.actions;

export default teamUser.reducer;
