import { createSlice } from "@reduxjs/toolkit";
import { JOB_MENU_ENUM } from "src/constants";

function initFetchParams() {
  return {
    size: 30,
    page: 1,
    statuses: "WAITING",
    candidateStageChildType: undefined,
    jobRole: undefined,
    jobIds: undefined,
  };
}

function initParams() {
  return {
    statuses: "WAITING",
    candidateStageChildType: undefined,
    menuSelectedKeys: ["all-all"],
    menuOpenKeys: Object.keys(JOB_MENU_ENUM),
  };
}

function initialState() {
  return {
    // 已选的参数
    params: initParams(),
    // 简历表格查询参数
    fetchParams: initFetchParams(),
    // 职位搜索名称
    jobSearch: null,

    // 是否批量操作
    showBatch: false,
    // 批量选择keys
    selectedRowKeys: [],
  };
}

// 候选人页面 store
export const tableCandidate = createSlice({
  name: "tableCandidate",
  initialState: initialState(),
  reducers: {
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
    setFetchParams: (state, action) => {
      state.fetchParams = {
        ...state.fetchParams,
        ...action.payload,
      };
    },
    setInitFetchParams: (state) => {
      state.fetchParams = initFetchParams();
    },
    setInitParams: (state) => {
      state.params = initParams();
    },
    setJobSearch: (state, action) => {
      state.jobSearch = action.payload;
    },
    setShowBatch: (state, action) => {
      state.showBatch = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    // 初始化批量操作参数
    setInitPatch: (state) => {
      state.showBatch = false;
      state.selectedRowKeys = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setParams,
  setJobSearch,
  setShowBatch,
  setSelectedRowKeys,
  setInitPatch,
  setFetchParams,
  setInitFetchParams,
  setInitParams,
} = tableCandidate.actions;

export default tableCandidate.reducer;
