import { createSlice } from "@reduxjs/toolkit";


function init() {
  return {
    // job 或 demand
    tabKey: "job",
    // 简历详情id
    jobIdDetail: null,
  };
}

export const jobTable = createSlice({
  name: "jobTable",
  initialState: init(),
  reducers: {
    setTabKey: (state, action) => {
      state.tabKey = action.payload;
    },
    setJobIdDetail: (state, action) => {
      state.jobIdDetail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTabKey, setJobIdDetail } = jobTable.actions;

export default jobTable.reducer;
