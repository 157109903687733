import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import BackIcon from "src/svgs/BackIcon";

import styles from "./index.module.less";

const BackComp = ({ onGoBack }) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const noCanBack = searchParams.get("no-can-back");

  const onClick = () => {
    onGoBack ? onGoBack() : navigate(-1);
  };

  if (noCanBack) {
    return null;
  }
  return (
    <div className={styles.btn} onClick={onClick}>
      <BackIcon />
    </div>
  );
};

export default BackComp;
