import { createSlice } from "@reduxjs/toolkit";

function initialState() {
  return {
    // 选择的参数
    params: {},
    // 查询的参数
    fetchParams: {
      page: 1,
      size: 30,
      prop: "id",
      order: "desc",
    },

    // 是否批量操作
    showBatch: false,
    // 批量选择keys
    selectedRowKeys: [],
  };
}

export const talentTable = createSlice({
  name: "talentTable",
  initialState: initialState(),
  reducers: {
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
    setFetchParams: (state, action) => {
      state.fetchParams = {
        ...state.fetchParams,
        ...action.payload,
      };
    },

    // 回到初始状态
    setInit: () => {
      return initialState();
    },

    setShowBatch: (state, action) => {
      state.showBatch = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    // 初始化批量操作参数
    setInitPatch: (state) => {
      state.showBatch = false;
      state.selectedRowKeys = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setParams,
  setFetchParams,
  setShowBatch,
  setSelectedRowKeys,
  setInitPatch,
  setInit,
} = talentTable.actions;

export default talentTable.reducer;
