import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { store } from "./store";
import { Provider } from "react-redux";
import ErrorBoundary from "./layouts/ErrorBoundary";
// antd 中文
import zhCN from "antd/lib/locale/zh_CN";

// dayjs 中文
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

import "./monitor";

import "./index.css";

dayjs.locale("zh-cn");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter
        basename={process.env.NODE_ENV === "production" ? "/job" : "/"}
      >
        <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
