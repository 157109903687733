import { logger } from "src/utils/logger";
import { TrackerSend } from "src/utils/tracker";

window.addEventListener("error", (event) => {
  logger("error", event);
  // 脚本加载错误
  if (event.target && (event.target.src || event.target.href)) {
    TrackerSend({
      errorType: "resourceError", // js执行错误
      filename: event.target.src || event.target.href, // 哪个文件报错了
      tagName: event.target.tagName,
      stack: event?.error?.stack,
      event,
    });
  } else {
    TrackerSend({
      errorType: "jsError", // js执行错误
      message: event.message, // 报错信息
      filename: event.filename, // 哪个文件报错了
      stack: event?.error?.stack,
      event,
    });
  }
});

window.addEventListener("unhandledrejection", (event) => {
  logger("unhandledrejection", event);
  let message;
  let filename;
  let line = 0;
  let column = 0;
  const reason = event.reason;

  if (reason instanceof Response) {
    message = `request error ${reason?.url} ${reason?.status}`;
  } else if (typeof reason === "string") {
    message = reason;
  } else if (typeof reason === "object") {
    message = reason.message;
    if (reason.stack) {
      const matchResult = reason.stack.match(/at\s+(.+):(\d+):(\d+)/);
      filename = matchResult[1];
      line = matchResult[2];
      column = matchResult[3];
    }
  }
  TrackerSend({
    errorType: "promiseError", // js执行错误
    message, // 报错信息
    filename, // 哪个文件报错了
    position: `${line}:${column}`, // 报错的行列位置
    stack: event?.reason?.stack,
    reason,
    event
  });
});
