import React from "react";

import BackComp from "src/components/BackComp";

import styles from "./index.module.less";

const HeaderB = ({ title, onGoBack, rightComp }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.left}>
        <BackComp onGoBack={onGoBack} />
        <span className={styles.name}>{title}</span>
      </div>
      <div>{rightComp}</div>
    </div>
  );
};

export default React.memo(HeaderB);
