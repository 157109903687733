import React from "react";

function PhoneIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <defs>
        <path id="path_0" d="M0 0H16V16H0z"></path>
      </defs>
      <mask id="bg-mask-0" fill="#fff">
        <use xlinkHref="#path_0"></use>
      </mask>
      <g mask="url(#bg-mask-0)">
        <path
          fill="#9AA4AD"
          fillRule="evenodd"
          d="M9.08 6.53a.678.678 0 00-.62-.01l-1.57.78s-1.17-.22-2.12-1.17C3.82 5.18 3.59 4 3.59 4l.79-1.57a.68.68 0 00-.02-.62L3.55.34A.672.672 0 002.96 0H1.27C.41 0-.21.8.07 1.62c.56 1.67 1.64 4.27 3.29 5.92 1.65 1.65 4.25 2.73 5.92 3.29.82.28 1.62-.34 1.62-1.2v-1.7c0-.24-.13-.46-.34-.58l-1.48-.82z"
          opacity="1"
          transform="translate(2.7 2.562)"
        ></path>
        <path
          stroke="#9AA4AD"
          strokeDasharray="0 0"
          strokeOpacity="1"
          strokeWidth="1.333"
          d="M11.78 9.092a.678.678 0 00-.62-.01l-1.57.78s-1.17-.22-2.12-1.17c-.95-.95-1.18-2.13-1.18-2.13l.79-1.57a.68.68 0 00-.02-.62l-.81-1.47a.672.672 0 00-.59-.34H3.97c-.86 0-1.48.8-1.2 1.62.56 1.67 1.64 4.27 3.29 5.92 1.65 1.65 4.25 2.73 5.92 3.29.82.28 1.62-.34 1.62-1.2v-1.7c0-.24-.13-.46-.34-.58l-1.48-.82z"
        ></path>
      </g>
    </svg>
  );
}

export default PhoneIcon;
