import { createSlice } from "@reduxjs/toolkit";
import dayjs from 'dayjs'

const start = dayjs().weekday(1); // 周初
const end = dayjs().weekday(7); // 周末


function initReportParams() {
  return {
    page: 1,
    size: 20,
    start: start.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
  }
}


export const reportDetail = createSlice({
  name: "reportDetail",
  initialState:{
    reportParams:initReportParams()
  },
  reducers: {
    setReportParams: (state, action) => {
      state.reportParams = {
        ...state.reportParams,
        ...action.payload
      }
    },
    // 初始化参数
    resetReportParams: (state) => {
      state.reportParams = initReportParams()
    }
  },
});

// Action creators are generated for each case reducer function
export const { setReportParams, resetReportParams } = reportDetail.actions;

export default reportDetail.reducer;
