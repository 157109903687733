import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    // {
    //     key:{
    //         file:'',
    //         uploadStatus
    //     }
    // }
    uploadMap:{},
    // 已删除的数组
    deleteArr:[]
}

// 酷应用筛选列表页
export const uploadResume = createSlice({
  name: 'uploadResume',
  initialState,
  reducers: {
    setUploadMap: (state, action) => {
      state.uploadMap = action.payload
    },
    setDeleteArr: (state, action) => {
      state.deleteArr = [...state.deleteArr, ...action.payload]
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUploadMap, setDeleteArr } = uploadResume.actions

export default uploadResume.reducer