import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const start = dayjs().weekday(1).format("YYYY-MM-DD"); // 周初
const end = dayjs().weekday(7).format("YYYY-MM-DD"); // 周末

function initialState() {
  return {
    // 已选的参数
    params: {
      showTodo: true,
      isMy: false,
      jobRole: undefined,
      dateArray: [start, end],
      // 是否修改过日期
      isEditDate: false,
    },
    // 查询参数
    fetchParams: {
      tableSize: 30,
      page: 1,
    },
    // 我的待办数
    todoNumber: 0,
  };
}

// 候选人页面 store
export const workbench = createSlice({
  name: "workbench",
  initialState: initialState(),
  reducers: {
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
    onInitDateArray: (state) => {
      state.params = {
        ...state.params,
        dateArray: [start, end],
      };
    },
    setFetchParams: (state, action) => {
      state.fetchParams = {
        ...state.fetchParams,
        ...action.payload,
      };
    },
    setTodoNumber: (state, action) => {
      state.todoNumber = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setParams, setFetchParams, onInitDateArray, setTodoNumber } = workbench.actions;

export default workbench.reducer;
