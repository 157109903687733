import React from "react";

// 待反馈
function PendingIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={className}
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#FFAC34"
        d="M0 512a512 512 0 101024 0A512 512 0 100 512z"
      ></path>
      <path
        fill="#FFF"
        d="M512 192a320 320 0 110 640 320 320 0 010-640zm0 64a256 256 0 100 512 256 256 0 000-512z"
      ></path>
      <path
        fill="#FFF"
        d="M544 335.552v165.184l69.76 86.848-49.92 40.064L480 523.264V335.552z"
      ></path>
    </svg>
  );
}

export default PendingIcon;