import React from "react";

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6.63 0L1.37 5.55 0 7l1.37 1.44L6.63 14 8 12.56 2.74 7 8 1.44 6.63 0z"
        opacity="0.74"
      ></path>
    </svg>
  );
}

export default BackIcon;