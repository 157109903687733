import { createSlice } from "@reduxjs/toolkit";


function init() {
  return {
    // 选择的参数
    params: {
      typeDate:'all',
      typeMe:'all',
      appointedDateArray:[undefined, undefined]
    },
    // 查询的参数
    fetchParams: {
      page: 1,
      size:30,
      sort: 'orderedTime,desc'
    },
  };
}

export const interviewTable = createSlice({
  name: "interviewTable",
  initialState: init(),
  reducers: {
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
    setFetchParams: (state, action) => {
      state.fetchParams = {
        ...state.fetchParams,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setParams, setFetchParams } = interviewTable.actions;

export default interviewTable.reducer;
