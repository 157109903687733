import React from "react";

// 待定
function Icon({className, fill="#BBCFDD"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 200 200"
      className={className}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            fill={fill}
            fillRule="nonzero"
            d="M100 0c55.228 0 100 44.772 100 100s-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0z"
          ></path>
          <path
            fill="#FFF"
            d="M133.333 55.556H66.667v16.666A22.222 22.222 0 0075.556 90l13.322 9.989L75.556 110a22.222 22.222 0 00-8.89 17.778v16.666h66.667v-16.666A22.222 22.222 0 00124.444 110l-13.333-10 13.333-10a22.222 22.222 0 008.89-17.778V55.556z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;