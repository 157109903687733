import SlsTracker from "@aliyun-sls/web-track-browser";
// import { store } from "../store";

// 跟踪上传日志
const opts = {
  host: "cn-zhangjiakou.log.aliyuncs.com", // 所在区域的host
  project: "sc-micro-log-prod", // project名称
  logstore: "sc-web", // logstore名称
  time: 10, // 定义时间，默认是10秒，number类型，选填
  count: 10, // 定义数据条数，默认是10条，number类型，选填
  topic: "识才招聘",
  source: "pc端",
  tags: {
    env: process.env.REACT_APP_ENV,
    userAgent: window.navigator.userAgent,
    version:process.env.REACT_APP_VERSION,
  },
};

const Tracker = new SlsTracker(opts);

export async function TrackerSend(obj) {
  const res = await import("../store");

  let userInfo = {};
  if (res?.store) {
    const state = res.store.getState();
    userInfo = state?.teamUser?.userInfo;
  }

  Tracker.send({
    pageUrl: window.location.href,
    corpId: userInfo.corpId,
    teamKey: userInfo.key,
    name: userInfo.name,
    userId: userInfo.id,
    teamName: userInfo.teamName,
    dingUserId: userInfo.dingUserId,
    role: userInfo.role,
    ...obj,
  });
}
