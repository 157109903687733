import React from "react";

// 现场
function Icon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={className}
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#357ED6"
        d="M0 507.108a507.108 507.108 0 101014.215 0A507.108 507.108 0 100 507.108z"
      ></path>
      <path
        fill="#FFF"
        d="M697.06 468.992c0-101.604-85.277-183.751-190.01-183.751-105.073 0-190.122 82.375-190.122 183.751 0 3.243.284 6.428.569 9.387-.285 1.479-.569 2.958-.569 4.665 0 113.208 175.616 241.208 175.616 241.208s14.734 10.639 29.184 0c14.677-10.58 175.56-128.227 175.56-241.208a29.412 29.412 0 00-.513-4.665c0-2.959.285-6.144.285-9.387zm-190.01 70.77c-40.39 0-73.102-31.517-73.102-70.77 0-38.912 32.712-70.77 73.046-70.77 40.39 0 73.102 31.574 73.102 70.77 0 39.253-32.768 70.77-73.102 70.77z"
      ></path>
    </svg>
  );
}

export default Icon;