import React from "react";

// 视频
function Icon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={className}
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#357ED6"
        d="M0 507.108a507.108 507.108 0 101014.215 0A507.108 507.108 0 100 507.108z"
      ></path>
      <path
        fill="#FFF"
        d="M380.36 469.049c20.024 0 36.237-17.01 36.237-38.059 0-21.049-16.213-38.058-36.238-38.058s-36.238 17.066-36.238 38.115c0 20.992 16.213 38.002 36.238 38.002zm253.553-27.193l102.457-39.48a17.863 17.863 0 0123.267 11.377 19.74 19.74 0 011.024 6.599v192.569a18.603 18.603 0 01-18.09 19.115 18.204 18.204 0 01-6.144-1.138l-102.514-39.424V659c0 21.163-16.213 38.286-36.238 38.286H289.792c-20.025 0-36.238-17.123-36.238-38.286V355.214c0-21.106 16.213-38.286 36.238-38.286h307.883c20.025 0 36.238 17.18 36.238 38.343v86.585z"
      ></path>
    </svg>
  );
}

export default Icon;