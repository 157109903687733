import React from "react";

// 不通过
function RejectedIcon({className, fill="#E84D4D"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={className}
      viewBox="0 0 1024 1024"
    >
      <path
        fill={fill}
        d="M0 512a512 512 0 101024 0A512 512 0 100 512z"
      ></path>
      <path
        fill="#FFF"
        d="M729.242 294.758a43.878 43.878 0 010 62.106L574.106 512l155.136 155.136a43.878 43.878 0 11-62.106 62.106L512 574.054 356.864 729.19a43.878 43.878 0 01-62.106-62.054L449.894 512 294.758 356.864a43.878 43.878 0 0162.106-62.106L512 449.894l155.136-155.136a43.878 43.878 0 0162.106 0z"
      ></path>
    </svg>
  );
}

export default RejectedIcon;