import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import LoadingComp from 'src/components/LoadingComp'

import "./App.less";

// 渲染路由
function RouteElement() {
  const element = useRoutes(routes);
  return element;
}

function App() {
  return (
    <Suspense
      fallback={
       <LoadingComp />
      }
    >
      <RouteElement />
    </Suspense>
  );
}

export default App;
