import React from "react";
import { Menu } from "antd";
import HeaderB from "src/components/HeaderB";
import { Outlet, useMatch, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useGetDingUserInfoQuery } from "src/services/auth";

import styles from "./Settings.module.less";

const Settings = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.teamUser);

  // 是否是钉钉管理员
  let isDingAdmin = false;
  const { data: dingData } = useGetDingUserInfoQuery();

  if (dingData?.code === 200) {
    isDingAdmin = dingData?.data?.admin;
  }

  let match = useMatch("/settings/:key");

  const onClick = ({ key }) => {
    navigate(`/settings/${key}`, { replace: true });
  };

  const isAdmin = userInfo.role === "ADMIN";
  const isHR = userInfo.role === "HR";

  const isAdminOrHR = isAdmin || isHR;

  const items = [
    {
      key: "user",
      label: "个人信息",
      show: true,
    },
    {
      show: isAdminOrHR || isDingAdmin,
      key: "team-users",
      label: "团队管理",
    },
    {
      show: isAdmin,
      key: "team-auth",
      label: "角色权限管理",
    },
    {
      show: isAdmin,
      key: "approval",
      label: "审批管理",
    },
    {
      show: isAdmin,
      key: "demand-setting",
      label: "招聘需求管理",
    },
    {
      show: isAdmin,
      key: "job-setting",
      label: "职位管理",
    },
    {
      show: isAdminOrHR,
      key: "resume",
      label: "简历录入",
      children: [
        {
          key: "duplication-check",
          label: "简历查重设置",
        },
        {
          key: "emails",
          label: "邮箱收取简历",
        },
      ],
    },
    {
      show: isAdminOrHR,
      key: "protection",
      label: "保护期设置",
    },
    {
      show: isAdminOrHR,
      key: "category",
      label: "简历分类信息设置",
      children: [
        {
          key: "channel",
          label: "渠道管理",
        },
        {
          key: "source",
          label: "来源管理",
        },
        {
          key: "archive",
          label: "淘汰、入职、归档原因设置",
        },
      ],
    },

    {
      show: isAdminOrHR,
      key: "interview-setting",
      label: "面试相关",
      children: [
        {
          key: "interview-feedback",
          label: "面试评价表",
        },
        {
          key: "interview",
          label: "筛选面试反馈设置",
        },
        {
          key: "address",
          label: "现场面试地址管理",
        },
      ],
    },

    {
      show: isAdminOrHR,
      key: "template",
      label: "候选人通知模板设置",
    },

    {
      show: isAdminOrHR,
      key: "notice",
      label: "系统通知消息设置",
    },

    {
      show: isAdmin,
      key: "fields",
      label: "系统字段管理",
      children: [
        {
          key: "resume-fields",
          label: "候选人字段管理",
        },
        {
          key: "demand-fields",
          label: "招聘需求字段管理",
        },
        {
          key: "job-fields",
          label: "职位字段管理",
        },
        {
          key: "member-fields",
          label: "成员字段管理",
        },
      ],
    },
  ];

  let list = [];
  items.forEach((item) => {
    if (item.show) {
      let itemNew = {
        key: item.key,
        label: item.label,
        path: "12",
        children: item.children,
      };

      list.push(itemNew);
    }
  });

  const RightComp = () => {
    return <div className="font12">v{process.env.REACT_APP_VERSION}</div>;
  };

  return (
    <div>
      <HeaderB title="设置中心" rightComp={<RightComp />} />
      <div className={styles.container}>
        <Menu
          className={styles.menu}
          onClick={onClick}
          style={{ width: 256 }}
          mode="inline"
          items={list}
          defaultSelectedKeys={[match?.params?.key]}
        />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Settings;
