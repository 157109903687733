import React from "react";

// 通过
function AcceptedIcon({className, fill="#15BC83"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      className={className}
      viewBox="0 0 1024 1024"
    >
      <path
        fill={fill}
        d="M0 512a512 512 0 101024 0A512 512 0 100 512z"
      ></path>
      <path
        fill="#FFF"
        d="M777.775 344.809a39.377 39.377 0 01-3.91 55.575L446.883 684.591a39.191 39.191 0 01-18.525 8.983l-4.98.605a39.33 39.33 0 01-32.024-13.498l-129.21-148.62a39.377 39.377 0 1159.439-51.665l103.33 118.877L722.2 340.945a39.377 39.377 0 0155.529 3.864z"
      ></path>
    </svg>
  );
}

export default AcceptedIcon;