import Cookies from 'js-cookie';

const LOGIN_TOKEN = 'login_token';
const CORP_ID = 'corpId';


const TEAM_KEY = 'team_key';

/**
 * 获取登录token
 * @returns
 */
export function getLoginToken() {
  return Cookies.get(LOGIN_TOKEN) || '';
}

/**
 * 获取corpId
 * @returns
 */
export function getCorpId() {
  return window.localStorage.getItem(CORP_ID) || '';
}

/**
 * 设置corpId
 * @returns
 */
export function setCorpId(corpId) {
  window.localStorage.setItem(CORP_ID, corpId);
  // 存到cookie，用于预发环境静态资源区别
  Cookies.set(CORP_ID, corpId);
}

/**
 * 移除corpId
 */
export function removeCorpId() {
  window.localStorage.removeItem(CORP_ID);
}

/**
 *
 * @param {*} token
 * @param {*} expireTime 到期时间戳 毫秒
 * @param {*} corpId 组织id
 */
export function setToken({ token, expireTime, corpId = '' }) {
  let expires = null;

  if (expireTime) {
    expires = new Date(new Date().getTime() + expireTime * 1000);
  }
  setCorpId(corpId);
  Cookies.set(LOGIN_TOKEN, token, { expires, sameSite: 'Lax' });
}

/**
 * 移除token
 */
export function removeToken() {
  Cookies.remove(LOGIN_TOKEN);
}




/**
 * 获取团队key
 * @returns
 */
export function getTeamKeyStorage() {
  const result =  window.localStorage.getItem(TEAM_KEY) || Cookies.get(TEAM_KEY) || '';
  return result;
}

/**
 * 设置团队key
 * @returns
 */
export function setTeamKeyStorage(teamKey) {
  Cookies.set(TEAM_KEY, teamKey);
  window.localStorage.setItem(TEAM_KEY, teamKey);
}
